





















































import { AppProvider } from '@/app-providers'
import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'

@Observer
@Component
export default class Footer extends Vue {
  @Inject() providers!: AppProvider
}
